
import { useTranslation } from 'react-i18next';
import React from 'react';

import Acknowledgment from '../components/Acknowledgment';
import Layout from '../components/Layout';
import SEO from '../components/SEO';

const AcknowledgmentPage = () => {
  const { t } = useTranslation();

  return (
    <Layout>
      <SEO title={t('acknowledgment')} />
      <section className="container mx-auto p-8 lg:flex">
        <Acknowledgment />
      </section>
    </Layout>
  );
};

export default AcknowledgmentPage;
